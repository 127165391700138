import React, { Component } from 'react'

// import Layout from "../components/layout"
import SEO from "../components/seo"
// import Iframe from "../components/iFrame"
// import ModalButton from '../components/modalButton'

import '../styles/calendar.css'

export class tickets extends Component {

    componentDidMount() {
        window.location.href = "https://www.eventbrite.com/o/found-stages-18287682913"
    }
    render() {
        return <SEO title="Tickets" description="Get tickets for Found Stages events including Frankenstein's Ball, Frankenstein's Funeral and the Wine & Reading Series" />
        // const iframe = '<iframe  src="https://www.eventbrite.com/calendar-widget?eid=68384362469" frameborder="0" height="652" width="195" marginheight="0" marginwidth="0" scrolling="no" allowtransparency="true"></iframe>'
        // return (
        //     <Layout>
        //     <SEO title="Tickets" />
        //     <div style={{padding:'118px 1em 1em 1em'}}>
        //                 <div class="calendar-title">
        //                     Find Tickets for Upcoming Events
        //                 </div>
        //         <div className="calendar-wrapper">
        //             <div className="calendar-content-wrapper">
        //                     <div class="calendar-content-block text-block">
        //                         <h2 class="glowist forty-font">Frankenstein's Funeral</h2>
        //                         <div class="byline">
        //                             <p><strong>Dates:</strong> October 4 through November 3</p>
        //                         </div>
        //                         <p>Frankenstein’s Funeral takes audiences on a physical journey, guided by Mary Shelley, through her novel and the Gothic campus of St. John’s Lutheran Church at Halloween.</p>
        //                     </div>

        //                     <div class="calendar-content-block text-block">
        //                         <h2 class="glowist forty-font">Wine and Reading Series</h2>
        //                         <div class="byline">
        //                             <p><strong>Dates:</strong> May through October</p>
        //                         </div>
        //                         <p>On the second Sunday of each month, May through October, a new original play will be unveiled amid the relaxing backdrop of beautiful Dunwoody Nature Center.</p>
        //                     </div>

        //                 </div>
                     

        //             <div className="calendar-iframe-wrapper" >
        //                 {/* <iframe  src="https://www.eventbrite.com/calendar-widget?eid=68384362469" frameborder="0" height="652" width="195" marginheight="0" marginwidth="0" scrolling="no" allowtransparency="true"></iframe><div style={{fontFamily: 'Helvetica, Arial', fontSize:12, padding:'10px 0 5px', margin:2, width:195, textAlign:'center'}} ><a class="powered-by-eb" style={{color: '#ADB0B6', textDecoration: 'none'}} target="_blank" href="http://www.eventbrite.com/">Powered by Eventbrite</a></div> */}
        //                 <Iframe iframe={iframe} />
        //             </div>
        //         </div>
        //     </div>
        //     <ModalButton />
        //     </Layout>
        // )
    }
}

export default tickets
